<template lang="pug">
#services
  ui-tabs.page-tabs(v-if="isTmManager" v-model="orderPageMode" :tabs="tabs")
  orders-list(v-if="orderPageMode === OrderPageTabs.Orders")
  .bills-list(v-if="isTmManager && orderPageMode === OrderPageTabs.Bills")
    invoice-page
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRouteQuery } from '@vueuse/router';
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";

import UiTabs from "~/components/ui/tab/UiTabs.vue";
import OrdersList from "~/views/orders/OrdersList.vue";
import InvoicePage from "~/views/settings/tariff/InvoicePage.vue";

const OrderPageTabs = {
  Orders: "orders",
  Bills: "bills",
}

export default defineComponent({
  name: "TenderServiceIndex",
  components: {
    UiTabs,
    OrdersList,
    InvoicePage,
  },
  setup() {

    const orderPageMode = useRouteQuery('tab', OrderPageTabs.Orders, { mode: 'push' });

    const tabs = [
      { name: 'Список заказанных услуг', key: OrderPageTabs.Orders },
      { name: 'Список счетов на оплату', key: OrderPageTabs.Bills },
    ]

    const { isTmManager } = useUserAccess();

    return {
      tabs,
      orderPageMode,
      isTmManager,
      OrderPageTabs,
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/main";
@import "@/assets/styles/pages/main";

#services {
  @include page-view;

  .page-tabs {
    margin-top: 16px;
  }

  .bills-list {
    display: flex;
    margin-top: 24px;
  }
}
</style>
